<template>
	<main>	
		<div class="carousel-inner">			
          <div class="carousel-item active ba-1" data-bs-interval="10000" v-if="date > 0">	
          <img :src="require(`@/assets/banners/days/${date}.jpg`)" class="d-block w-100" alt="...">
	      </div>      
	    </div>	
		<!--  -->
		<!--  -->
		<dialog id="statemodal" style="max-width: 550px; border-radius: 10px; margin-top: 20px; margin-bottom: 20px;"> 
		<div>
			<h6><span class="text-red">Please select State, District and Taluk you belongs too.</span></h6>
		</div>
    <div class="col-md-12" style="margin-top: 20px;margin-bottom: 20px;text-align: center;min">
            <label class="form-label">Select State<span class="text-red">*</span></label>
      <select id="stateDropdown" v-model="selectedState" style="min-width: 300px;height: 30px;">		
        <option v-for="state in filteredStates" :key="state" :value="state">{{ state }}</option>
      </select>          
    </div>
    <div>
      <span v-if="error.state != null" class="invalid-feedback">{{ error.state}}</span> 
    </div>
    

    <div class="col-md-12" style="margin-top: 20px;margin-bottom: 20px;text-align: center;">
      <label class="form-label">Select District<span class="text-red">*</span></label>      
      <select id="districtDropdown" v-model="selectedDistrict" style="min-width: 300px;height: 30px;">
        <option v-for="district in filteredDistricts" :key="district" :value="district">{{ district }}</option>
      </select>        
    </div>
    <div>
      <span v-if="error.district != null" class="invalid-feedback">{{ error.district}}</span>
    </div>
    

	<div class="col-md-12" style="margin-top: 20px;margin-bottom: 20px;text-align: center;">
                  <label class="form-label">Enter Taluk<span class="text-red">*</span></label>
      <input type="text" style="min-width: 300px;height: 30px;" v-model="citySearch" @input="filterCities" placeholder="Taluk...">
      <div >
            <span v-if="error.taluk != ''" class="invalid-feedback">{{ error.taluk}}</span>
      </div>      
    </div>

	<div class="col-md-12" style="margin-top: 20px;margin-bottom: 20px;text-align: center;">
                  <label class="form-label">Enter Pincode<span class="text-red">*</span></label>
      <input type="number" style="min-width: 300px;height: 30px;" v-model="pincode"  placeholder="Pincode..." max="6">      
    </div>
    <div>
      <span v-if="error.pincode != ''" class="invalid-feedback">{{ error.pincode}}</span>
    </div>
    <div class="col-md-12" style="text-align: center;">
             <button class="blue-btn w-100 mr-t15 br8 h50 flex-grow-1 text-uppercase" @click="stateupdate()">Submit</button>
      </div>
	</dialog>

	<section class="pt-0 pt-sm-0">
			<!-- ###### Event start###### -->	
			<div class="container" v-if='counts!="0"'>
			<carousel  :items-to-show="1" autoplay="10000" transition="500" wrapAround="false" >
				<slide v-for="item in events" :key="item">		
					<div v-if="item.event_csc==true">
						<h3 class="col-12 text-center">Ongoing Events</h3>	
						<br>	
						<a href="#" class="card shadow h-100" @click.prevent="viewEvent(item.name)">
							<div class="position-relative">
								<div v-if="item.temple==null">
									<img style="height: 200px;" src="@/assets/banners/Default.png" alt="Card image">
								</div>
								<div  v-else>
									<img style="height: 300px;" :src="item.temple.images[0]?.image" alt="Card image">
								</div>					
							</div>							
							<div class="card-body">
								<h5 class="card-title me-2">
									<a href="#">{{ item.pooja.name  }}</a>
								</h5>
								<ul class="list-group list-group-borderless mb-0">
									<li class="list-group-item small pb-0" charset="UTF-8">
										{{ item.pooja.details  }}
									</li>									
									<li class="list-group-item small pb-0">
										<i class="bi bi-currency-rupee fa-fw h6 small mb-0"></i> {{ item.pooja.original_cost  }}
									</li>
								</ul>
								<p style="float: left;">From: {{moment(item.start).format('MMMM Do')}}</p>
								<p style="float: right;">To: {{moment(item.end).format('MMMM Do')}}</p>
							</div>
							<div class="card-footer">
								<div class="align-items-center">
									<button class="button p-0 mb-0" style="vertical-align:middle"><span>View Details</span> </button>
								</div>
							</div>
						</a>
					</div>					
				</slide>
				<template #addons>
					<navigation />
					<pagination />
				</template>
			</carousel>
		</div>

			<!-- ###### Event stop###### -->			
			<div class="container">				
				<div class="row">
					<div class="col-12 text-center">
						<h2 class="mb-0">Search for Temple</h2>						
					</div>
				</div>
				<div class="row">
					<div class="col-xl-8 mx-auto">
						<div class="bg-blur bg-white bg-opacity-10 border border-light border-opacity-25 rounded-3 p-4">
							<div class="row g-3 justify-content-center align-items-center">
								<div class="col-lg-10">
									<div class="form-input-dropdown position-relative">
										<input v-model="meta.search" placeholder="Search for Temple"
											class="form-control form-control-lg me-1 ps-5" @keyup.enter="search" type="text">
									</div>
								</div>
								<div class="col-lg-2 d-grid">
									<a class="btn btn-lg btn-primary mb-0" href="#" @click.prevent="search">Search</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row g-4">
					<div class="col-sm-6 col-lg-4 col-xl-3" v-for="temple in temples">
						<a href="#" class="card shadow h-100" @click.prevent="viewTemple(temple)">
							<div class="position-relative">
								<img :src="temple.images[0]?.image" class="temple card-img-middle" alt="Card image">
								<div class="card-img-overlay p-3 z-index-1">
									<div class="badge text-bg-success">
										{{ temple.city }}
									</div>
								</div>
							</div>
							<div class="card-body">
								<h5 class="card-title me-2">
									<a href="#">{{ temple.name }}</a>
								</h5>
								<ul class="list-group list-group-borderless mb-0">
									<li class="list-group-item small pb-0">
										<i class="bi bi-pin-map-fill fa-fw h6 small mb-0"></i> {{ temple.address }}
									</li>
									<li class="list-group-item small pb-0">
										<i class="bi bi-pin-map-fill fa-fw h6 small mb-0"></i> {{ temple.state }}
									</li>
								</ul>
							</div>
							<div class="card-footer">
								<div class="d-flex justify-content-between align-items-center">
									<button class="button p-0 mb-0" style="vertical-align:middle"><span> View Details </span></button>
								</div>
							</div>
						</a>
					</div>
					<Paginate class="pagination pagination-sm" :maxPage="meta.maxPage" :totalPages="meta.lastPage"
						:currentPage="meta.page" @pagechanged="onPageChange" />
				</div>
			</div>
		</section>
	<!-- </div>	 -->
	</main>
</template>
<script>
import Paginate from "@/components/Pagination.vue";
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

var today = new Date();
var moment = require('moment')


export default {
	name: "Home",
	components: {
		Paginate,
		Carousel,
		Slide,
		Pagination,
		Navigation,
	},
	data() {
		return {
		isModalOpen: false,
		indianStates: [
      'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar','Chandigarh (UT)', 'Chhattisgarh','Dadra and Nagar Haveli (UT)','Daman and Diu (UT)','Delhi (NCT)', 'Goa', 'Gujarat', 'Haryana',
      'Himachal Pradesh','Jammu and Kashmir', 'Jharkhand', 'Karnataka', 'Kerala','Ladakh (UT)','Lakshadweep (UT)', 'Madhya Pradesh', 'Maharashtra', 'Manipur',
      'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha','Puducherry (UT)', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana',
      'Tripura','Uttarakhand', 'Uttar Pradesh', 'West Bengal'].sort(),
	districts: {
      'Ladakh (UT)':['Kargil','Leh'],
      'Andhra Pradesh':  ['Alluri Sitharama Raju', 'Anakapalli', 'Ananthapuramu','Annamayya', 'Bapatla', 'Chittoor','Dr. B.R. Ambedkar Konaseema', 'East Godavari', 'Eluru','Guntur', 'Kakinada', 'Krishna','Kurnool', 'Nandyal', 'NTR','Palnadu', 'Parvathipuram Manyam', 'Prakasam','Srikakulam','Sri Potti Sriramulu Nellore', 'Sri Sathya Sai', 'Tirupati','Visakhapatnam', 'Vizianagaram', 'West Godavari','YSR',],
      'Arunachal Pradesh':  ['Anjaw', 'Changlang', 'East Kameng','East Siang', 'Itanagar capital complex', 'Kamle','Kra Daadi', 'Kurung Kumey', 'Lepa Rada','Lohit', 'Longding', 'Lower Dibang Valley','Lower Siang', 'Lower Subansiri', 'Namsai','Pakke-Kessang', 'Papum Pare', 'Shi Yomi','Siang','Tawang', 'Tirap', 'Upper Dibang Valley','Upper Siang', 'Upper Subansiri', 'West Kameng','West Siang',],
      'Assam':  ['Baksa', 'Barpeta', 'Biswanath','Bongaigaon', 'Cachar', 'Charaideo','Chirang', 'Darrang', 'Dhemaji','Dhubri', 'Dibrugarh','Dima Hasao','Goalpara', 'Golaghat', 'Hailakandi','Hojai', 'Jorhat', 'Kamrup','Kamrup Metropolitan','Karbi Anglong', 'Karimganj', 'Kokrajhar','Lakhimpur', 'Majuli', 'Morigaon','Nagaon','Nalbari', 'Sivasagar', 'South Salmara Mankachar','Sonitpur','Tamulpur','Tinsukia','Udalguri','West Karbi Anglong',],
      "Bihar":[  
            "Araria",
            "Arwal",
            "Aurangabad",
            "Banka",
            "Begusarai",
            "Bhagalpur",
            "Bhojpur",
            "Buxar",
            "Darbhanga",
            "East Champaran (Motihari)",
            "Gaya",
            "Gopalganj",
            "Jamui",
            "Jehanabad",
            "Kaimur (Bhabua)",
            "Katihar",
            "Khagaria",
            "Kishanganj",
            "Lakhisarai",
            "Madhepura",
            "Madhubani",
            "Munger (Monghyr)",
            "Muzaffarpur",
            "Nalanda",
            "Nawada",
            "Patna",
            "Purnia (Purnea)",
            "Rohtas",
            "Saharsa",
            "Samastipur",
            "Saran",
            "Sheikhpura",
            "Sheohar",
            "Sitamarhi",
            "Siwan",
            "Supaul",
            "Vaishali",
            "West Champaran"
         ],
		  "Chandigarh (UT)":[  
            "Chandigarh"
         ],
      "Chhattisgarh":[  
            "Balod",
            "Baloda Bazar",
            "Balrampur",
            "Bastar",
            "Bemetara",
            "Bijapur",
            "Bilaspur",
            "Dantewada (South Bastar)",
            "Dhamtari",
            "Durg",
            "Gariyaband",
            "Janjgir-Champa",
            "Jashpur",
            "Kabirdham (Kawardha)",
            "Kanker (North Bastar)",
            "Kondagaon",
            "Korba",
            "Korea (Koriya)",
            "Mahasamund",
            "Mungeli",
            "Narayanpur",
            "Raigarh",
            "Raipur",
            "Rajnandgaon",
            "Sukma",
            "Surajpur  ",
            "Surguja"
         ],
      "Dadra and Nagar Haveli (UT)":  ["Dadra & Nagar Haveli"],
      "Daman and Diu (UT)":  [ "Daman","Diu"],
      "Delhi (NCT)":  [ "Central Delhi",
            "East Delhi",
            "New Delhi",
            "North Delhi",
            "North East  Delhi",
            "North West  Delhi",
            "Shahdara",
            "South Delhi",
            "South East Delhi",
            "South West  Delhi",
            "West Delhi"],
      "Goa":  [ "North Goa",
            "South Goa"],
      'Gujarat':  ["Ahmedabad",
            "Amreli",
            "Anand",
            "Aravalli",
            "Banaskantha (Palanpur)",
            "Bharuch",
            "Bhavnagar",
            "Botad",
            "Chhota Udepur",
            "Dahod",
            "Dangs (Ahwa)",
            "Devbhoomi Dwarka",
            "Gandhinagar",
            "Gir Somnath",
            "Jamnagar",
            "Junagadh",
            "Kachchh",
            "Kheda (Nadiad)",
            "Mahisagar",
            "Mehsana",
            "Morbi",
            "Narmada (Rajpipla)",
            "Navsari",
            "Panchmahal (Godhra)",
            "Patan",
            "Porbandar",
            "Rajkot",
            "Sabarkantha (Himmatnagar)",
            "Surat",
            "Surendranagar",
            "Tapi (Vyara)",
            "Vadodara",
            "Valsad"],
      'Haryana':  ["Ambala",
            "Bhiwani",
            "Charkhi Dadri",
            "Faridabad",
            "Fatehabad",
            "Gurgaon",
            "Hisar",
            "Jhajjar",
            "Jind",
            "Kaithal",
            "Karnal",
            "Kurukshetra",
            "Mahendragarh",
            "Mewat",
            "Palwal",
            "Panchkula",
            "Panipat",
            "Rewari",
            "Rohtak",
            "Sirsa",
            "Sonipat",
            "Yamunanagar"],
      "Himachal Pradesh":  ["Bilaspur",
            "Chamba",
            "Hamirpur",
            "Kangra",
            "Kinnaur",
            "Kullu",
            "Lahaul &amp; Spiti",
            "Mandi",
            "Shimla",
            "Sirmaur (Sirmour)",
            "Solan",
            "Una"],
      "Jammu and Kashmir":  ["Anantnag",
            "Bandipore",
            "Baramulla",
            "Budgam",
            "Doda",
            "Ganderbal",
            "Jammu",
            "Kargil",
            "Kathua",
            "Kishtwar",
            "Kulgam",
            "Kupwara",
            "Leh",
            "Poonch",
            "Pulwama",
            "Rajouri",
            "Ramban",
            "Reasi",
            "Samba",
            "Shopian",
            "Srinagar",
            "Udhampur"],
      'Jharkhand':  ["Bokaro",
            "Chatra",
            "Deoghar",
            "Dhanbad",
            "Dumka",
            "East Singhbhum",
            "Garhwa",
            "Giridih",
            "Godda",
            "Gumla",
            "Hazaribag",
            "Jamtara",
            "Khunti",
            "Koderma",
            "Latehar",
            "Lohardaga",
            "Pakur",
            "Palamu",
            "Ramgarh",
            "Ranchi",
            "Sahibganj",
            "Seraikela-Kharsawan",
            "Simdega",
            "West Singhbhum"],
      'Karnataka':  ["Bagalkot",
            "Ballari (Bellary)",
            "Belagavi (Belgaum)",
            "Bengaluru (Bangalore) Rural",
            "Bengaluru (Bangalore) Urban",
            "Bidar",
            "Chamarajanagar",
            "Chikballapur",
            "Chikkamagaluru (Chikmagalur)",
            "Chitradurga",
            "Dakshina Kannada",
            "Davangere",
            "Dharwad",
            "Gadag",
            "Hassan",
            "Haveri",
            "Kalaburagi (Gulbarga)",
            "Kodagu",
            "Kolar",
            "Koppal",
            "Mandya",
            "Mysuru (Mysore)",
            "Raichur",
            "Ramanagara",
            "Shivamogga (Shimoga)",
            "Tumakuru (Tumkur)",
            "Udupi",
            "Uttara Kannada (Karwar)",
            "Vijayapura (Bijapur)",
            "Yadgir"],
      'Kerala':  ["Alappuzha",
            "Ernakulam",
            "Idukki",
            "Kannur",
            "Kasaragod",
            "Kollam",
            "Kottayam",
            "Kozhikode",
            "Malappuram",
            "Palakkad",
            "Pathanamthitta",
            "Thiruvananthapuram",
            "Thrissur",
            "Wayanad"],
      'Lakshadweep (UT)':  ["Agatti",
            "Amini",
            "Androth",
            "Bithra",
            "Chethlath",
            "Kavaratti",
            "Kadmath",
            "Kalpeni",
            "Kilthan",
            "Minicoy"],
      "Madhya Pradesh":  ["Agar Malwa",
            "Alirajpur",
            "Anuppur",
            "Ashoknagar",
            "Balaghat",
            "Barwani",
            "Betul",
            "Bhind",
            "Bhopal",
            "Burhanpur",
            "Chhatarpur",
            "Chhindwara",
            "Damoh",
            "Datia",
            "Dewas",
            "Dhar",
            "Dindori",
            "Guna",
            "Gwalior",
            "Harda",
            "Hoshangabad",
            "Indore",
            "Jabalpur",
            "Jhabua",
            "Katni",
            "Khandwa",
            "Khargone",
            "Mandla",
            "Mandsaur",
            "Morena",
            "Narsinghpur",
            "Neemuch",
            "Panna",
            "Raisen",
            "Rajgarh",
            "Ratlam",
            "Rewa",
            "Sagar",
            "Satna",
            "Sehore",
            "Seoni",
            "Shahdol",
            "Shajapur",
            "Sheopur",
            "Shivpuri",
            "Sidhi",
            "Singrauli",
            "Tikamgarh",
            "Ujjain",
            "Umaria",
            "Vidisha"],
      'Maharashtra':  ["Ahmednagar",
            "Akola",
            "Amravati",
            "Aurangabad",
            "Beed",
            "Bhandara",
            "Buldhana",
            "Chandrapur",
            "Dhule",
            "Gadchiroli",
            "Gondia",
            "Hingoli",
            "Jalgaon",
            "Jalna",
            "Kolhapur",
            "Latur",
            "Mumbai City",
            "Mumbai Suburban",
            "Nagpur",
            "Nanded",
            "Nandurbar",
            "Nashik",
            "Osmanabad",
            "Palghar",
            "Parbhani",
            "Pune",
            "Raigad",
            "Ratnagiri",
            "Sangli",
            "Satara",
            "Sindhudurg",
            "Solapur",
            "Thane",
            "Wardha",
            "Washim",
            "Yavatmal"],
      'Manipur':  ["Bishnupur",
            "Chandel",
            "Churachandpur",
            "Imphal East",
            "Imphal West",
            "Jiribam",
            "Kakching",
            "Kamjong",
            "Kangpokpi",
            "Noney",
            "Pherzawl",
            "Senapati",
            "Tamenglong",
            "Tengnoupal",
            "Thoubal",
            "Ukhrul"],
      'Meghalaya':  ["East Garo Hills",
            "East Jaintia Hills",
            "East Khasi Hills",
            "North Garo Hills",
            "Ri Bhoi",
            "South Garo Hills",
            "South West Garo Hills ",
            "South West Khasi Hills",
            "West Garo Hills",
            "West Jaintia Hills",
            "West Khasi Hills"],
      'Mizoram':  [ "Aizawl",
            "Champhai",
            "Kolasib",
            "Lawngtlai",
            "Lunglei",
            "Mamit",
            "Saiha",
            "Serchhip"],
      'Nagaland':  ["Dimapur",
            "Kiphire",
            "Kohima",
            "Longleng",
            "Mokokchung",
            "Mon",
            "Peren",
            "Phek",
            "Tuensang",
            "Wokha",
            "Zunheboto"],
      'Odisha':  ["Angul",
            "Balangir",
            "Balasore",
            "Bargarh",
            "Bhadrak",
            "Boudh",
            "Cuttack",
            "Deogarh",
            "Dhenkanal",
            "Gajapati",
            "Ganjam",
            "Jagatsinghapur",
            "Jajpur",
            "Jharsuguda",
            "Kalahandi",
            "Kandhamal",
            "Kendrapara",
            "Kendujhar (Keonjhar)",
            "Khordha",
            "Koraput",
            "Malkangiri",
            "Mayurbhanj",
            "Nabarangpur",
            "Nayagarh",
            "Nuapada",
            "Puri",
            "Rayagada",
            "Sambalpur",
            "Sonepur",
            "Sundargarh"],
      'Puducherry (UT)':  [ "Karaikal",
            "Mahe",
            "Pondicherry",
            "Yanam"],
      'Punjab':  ["Amritsar",
            "Barnala",
            "Bathinda",
            "Faridkot",
            "Fatehgarh Sahib",
            "Fazilka",
            "Ferozepur",
            "Gurdaspur",
            "Hoshiarpur",
            "Jalandhar",
            "Kapurthala",
            "Ludhiana",
            "Mansa",
            "Moga",
            "Muktsar",
            "Nawanshahr (Shahid Bhagat Singh Nagar)",
            "Pathankot",
            "Patiala",
            "Rupnagar",
            "Sahibzada Ajit Singh Nagar (Mohali)",
            "Sangrur",
            "Tarn Taran"],
      'Rajasthan':  ["Ajmer",
            "Alwar",
            "Banswara",
            "Baran",
            "Barmer",
            "Bharatpur",
            "Bhilwara",
            "Bikaner",
            "Bundi",
            "Chittorgarh",
            "Churu",
            "Dausa",
            "Dholpur",
            "Dungarpur",
            "Hanumangarh",
            "Jaipur",
            "Jaisalmer",
            "Jalore",
            "Jhalawar",
            "Jhunjhunu",
            "Jodhpur",
            "Karauli",
            "Kota",
            "Nagaur",
            "Pali",
            "Pratapgarh",
            "Rajsamand",
            "Sawai Madhopur",
            "Sikar",
            "Sirohi",
            "Sri Ganganagar",
            "Tonk",
            "Udaipur"],
      'Sikkim':  [ "East Sikkim",
            "North Sikkim",
            "South Sikkim",
            "West Sikkim"],
      'Tamil Nadu':  ["Ariyalur",
            "Chennai",
            "Coimbatore",
            "Cuddalore",
            "Dharmapuri",
            "Dindigul",
            "Erode",
            "Kanchipuram",
            "Kanyakumari",
            "Karur",
            "Krishnagiri",
            "Madurai",
            "Nagapattinam",
            "Namakkal",
            "Nilgiris",
            "Perambalur",
            "Pudukkottai",
            "Ramanathapuram",
            "Salem",
            "Sivaganga",
            "Thanjavur",
            "Theni",
            "Thoothukudi (Tuticorin)",
            "Tiruchirappalli",
            "Tirunelveli",
            "Tiruppur",
            "Tiruvallur",
            "Tiruvannamalai",
            "Tiruvarur",
            "Vellore",
            "Viluppuram",
            "Virudhunagar"],
      'Telangana':  ["Adilabad",
            "Bhadradri Kothagudem",
            "Hyderabad",
            "Jagtial",
            "Jangaon",
            "Jayashankar Bhoopalpally",
            "Jogulamba Gadwal",
            "Kamareddy",
            "Karimnagar",
            "Khammam",
            "Komaram Bheem Asifabad",
            "Mahabubabad",
            "Mahabubnagar",
            "Mancherial",
            "Medak",
            "Medchal",
            "Nagarkurnool",
            "Nalgonda",
            "Nirmal",
            "Nizamabad",
            "Peddapalli",
            "Rajanna Sircilla",
            "Rangareddy",
            "Sangareddy",
            "Siddipet",
            "Suryapet",
            "Vikarabad",
            "Wanaparthy",
            "Warangal (Rural)",
            "Warangal (Urban)",
            "Yadadri Bhuvanagiri"],
      'Tripura':  ["Dhalai",
            "Gomati",
            "Khowai",
            "North Tripura",
            "Sepahijala",
            "South Tripura",
            "Unakoti",
            "West Tripura"],
      'Uttarakhand':  ["Almora",
            "Bageshwar",
            "Chamoli",
            "Champawat",
            "Dehradun",
            "Haridwar",
            "Nainital",
            "Pauri Garhwal",
            "Pithoragarh",
            "Rudraprayag",
            "Tehri Garhwal",
            "Udham Singh Nagar",
            "Uttarkashi"],
      'Uttar Pradesh':  ["Agra",
            "Aligarh",
            "Allahabad",
            "Ambedkar Nagar",
            "Amethi (Chatrapati Sahuji Mahraj Nagar)",
            "Amroha (J.P. Nagar)",
            "Auraiya",
            "Azamgarh",
            "Baghpat",
            "Bahraich",
            "Ballia",
            "Balrampur",
            "Banda",
            "Barabanki",
            "Bareilly",
            "Basti",
            "Bhadohi",
            "Bijnor",
            "Budaun",
            "Bulandshahr",
            "Chandauli",
            "Chitrakoot",
            "Deoria",
            "Etah",
            "Etawah",
            "Faizabad",
            "Farrukhabad",
            "Fatehpur",
            "Firozabad",
            "Gautam Buddha Nagar",
            "Ghaziabad",
            "Ghazipur",
            "Gonda",
            "Gorakhpur",
            "Hamirpur",
            "Hapur (Panchsheel Nagar)",
            "Hardoi",
            "Hathras",
            "Jalaun",
            "Jaunpur",
            "Jhansi",
            "Kannauj",
            "Kanpur Dehat",
            "Kanpur Nagar",
            "Kanshiram Nagar (Kasganj)",
            "Kaushambi",
            "Kushinagar (Padrauna)",
            "Lakhimpur - Kheri",
            "Lalitpur",
            "Lucknow",
            "Maharajganj",
            "Mahoba",
            "Mainpuri",
            "Mathura",
            "Mau",
            "Meerut",
            "Mirzapur",
            "Moradabad",
            "Muzaffarnagar",
            "Pilibhit",
            "Pratapgarh",
            "RaeBareli",
            "Rampur",
            "Saharanpur",
            "Sambhal (Bhim Nagar)",
            "Sant Kabir Nagar",
            "Shahjahanpur",
            "Shamali (Prabuddh Nagar)",
            "Shravasti",
            "Siddharth Nagar",
            "Sitapur",
            "Sonbhadra",
            "Sultanpur",
            "Unnao",
            "Varanasi"],
      'West Bengal':  ["Alipurduar",
            "Bankura",
            "Birbhum",
            "Burdwan (Bardhaman)",
            "Cooch Behar",
            "Dakshin Dinajpur (South Dinajpur)",
            "Darjeeling",
            "Hooghly",
            "Howrah",
            "Jalpaiguri",
            "Kalimpong",
            "Kolkata",
            "Malda",
            "Murshidabad",
            "Nadia",
            "North 24 Parganas",
            "Paschim Medinipur (West Medinipur)",
            "Purba Medinipur (East Medinipur)",
            "Purulia",
            "South 24 Parganas",
            "Uttar Dinajpur (North Dinajpur)"],
    },
	
    stateSearch: '',
    districtSearch: '',
    citySearch: '',
    selectedState: null,
    selectedDistrict: null,
    selectedCity: null,
			moment:moment,
			meta:{
				page: 1,
				size: 12,
				count: 0,
				maxPage: 1,
				lastPage: 1,
				search: "",
				ordering: "name_en",
				date:"2",
			},
			temples: [],
			date:'2',
			events:[],
			dates:'',
			counts:'0', 
			state:'',
            district:'',
            taluk:'',
            pincode:'',
            registerUser: {
                  state: "",
                  district: "",
                  taluk: "",
                  pincode: "",
            },
            error: {
                  state: "",
                  district: "",
                  taluk: "",
                  pincode: "",
            },
		}
	},
	mounted() {
		window.scrollTo(0,0);
		this.getTemples();		
		this.todaysDate();
		this.eventsDetails();	
		this.getProfile();	
	},
	computed: {
		filteredStates() {
      return this.indianStates.filter(state =>
        state.toLowerCase().includes(this.stateSearch.toLowerCase())
      );
    },
    filteredDistricts() {
      const districts = this.districts[this.selectedState] || [];
      return districts.filter(district =>
        district.toLowerCase().includes(this.districtSearch.toLowerCase())
      );
    },
	filteredCities() {
      const cities = this.cities[this.selectedDistrict] || [];
      return cities.filter(city =>
        city.toLowerCase().includes(this.citySearch.toLowerCase())
      );
    },
	
  },
	methods: {
      stateupdate(){            
            let vm = this;
            vm.error.state = "";
            vm.error.district = "";
            vm.error.taluk = "";
            vm.error.pincode = "";
            if (vm.selectedState == null) {
                vm.error.state = "Please select state";
                alert(vm.error.state);
                return;
            }
            if (vm.selectedDistrict == null) {
                vm.error.district = "Please select District";
                alert(vm.error.district);
                return;
            }
            if (vm.citySearch == '') {
                vm.error.taluk = "Please Enter Taluk";
                alert(vm.error.taluk);
                return;
            }
            if (vm.pincode == '') {
                vm.error.pincode = "Please Enter Pincode";
                alert(vm.error.pincode);
                return;
            }
            if ( vm.error.state == '' && vm.error.district == '' && vm.error.taluk == '' && vm.error.pincode == '' ) {
            vm.registerUser.state = vm.selectedState;
            vm.registerUser.district = vm.selectedDistrict;
            vm.registerUser.taluk =  vm.citySearch;
            vm.registerUser.pincode = vm.pincode;
            vm.$store.dispatch("put", { uri: "devotee/profile/update/", data: vm.registerUser })
        .then((response) => {
            // console.log(response);
            document.getElementById("statemodal").close();
            this.enableScroll();           
            })
        .catch((error) => {
            console.log("profile Update",error);
            });
      }
      },
      disableScroll() {
      document.body.style.overflow = 'hidden';
    },
    enableScroll() {
      document.body.style.overflow = 'auto';
    },
		onDistrictChange() {
      this.selectedCity = null;
    },
		onStateChange() {
      this.selectedDistrict = null;
      this.selectedCity = null;
    },
		filterCities() {
    },
		getProfile() {
            let vm = this;
            let loader = this.$loading.show();
            vm.$store.dispatch("get", { uri: "devotee/profile/"})
            .then((updateinfo) => {
                loader.hide();
            //     console.log("profile",updateinfo);
                vm.state=updateinfo.data.state;
                vm.district=updateinfo.data.district;
                vm.taluk=updateinfo.data.taluk;
			if(vm.state==null){
				document.getElementById("statemodal").showModal(); 
                        this.disableScroll();
			}
            })
            .catch((error) => {
                loader.hide();   
                console.log(error);           
            }); 
        },
		filterStates() {
    },
    filterDistricts() {
    },
		getTemples() {
			let vm = this;
			let uri = 'devotee/temple/?page=' + vm.meta.page + '&size=' + vm.meta.size + '&search=' + vm.meta.search + '&ordering=' + vm.meta.ordering;
			vm.$store.dispatch('get', { 'uri': uri }).then(function (response) {
				vm.temples = response.data.results;
				vm.meta.count = response.data.count;
				vm.meta.lastPage = Math.ceil(vm.meta.count / vm.meta.size);
				vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
			})
		},
		onPageChange(page) {
			this.meta.page = page;
			this.getTemples();
		},
		search() {
			this.meta.page = 1;
			this.getTemples();
		},
        viewTemple(temple) {
			this.$router.push('temples/' + temple.id);			
		}, 
		viewEvent(name) {
			this.$router.push('search/'+name);	
		},      
		todaysDate(){
			let vm = this;
			vm.date = today.getDay();
		},
		eventsDetails() {
            let vm = this;
            let loader = this.$loading.show();
            vm.$store.dispatch('get', { uri: "devotee/event/" })
            .then(response => {
				// console.log("Events",response);
                loader.hide();           
				vm.events = response.data.results;
				vm.counts = vm.events.length;
				// console.log("count",vm.counts);
            })
            .catch(errors => {
                loader.hide();
                console.log(errors);
            })
        },
	},
};
</script>
<style>
	.temple {
		border-radius: 15px;
		object-fit: cover;
		width: 100%;
		height: 250px;
	}
	.no-padding {
		background-color: transparent;
		margin-top: -70px;
		}
  @media only screen and (max-width: 375px) {
  .carousel__slide .carousel__slide--visible .carousel__slide--active {
    width:100%!important;
  }
  .carousel__slide .carousel__slide--clone .carousel__slide--visible .carousel__slide--prev{
	width:100%!important;
  }
  .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
}
.carousel-caption {
    top: 300px;
    text-align: left;
    background-color: transparent;
    padding: 35px;
    width: fit-content;
}
.carousel-item.active {
    display: block;
}
.ba-1 {
    background-size: cover;
    background-position: top left;
}
}
.imagetext{
	top: 150px;
	float: left;
}
.dialog-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog {
  background: white;
  padding: 20px;
  border-radius: 8px;
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.blue-btn {
    background: #0047BA;
    border: 1px solid #0047BA;
    border-radius: 4px;
    color: #FFFFFF;
    transition: all 0.3s linear 0s;
    font-size: 15px;
    height: 40px;
    padding: 5px 50px;
    box-sizing: border-box;
    font-weight: 500;
    letter-spacing: 1px;
}
</style>