<template>
    <header class="navbar-light header-sticky">
        <!-- Logo Nav START -->
        <nav class="navbar navbar-light navbar-expand-xl">
            <div class="container">
                <!-- Logo START -->                
                <router-link to="/" class="navbar-brand">                    
                    <img class="light-mode-item navbar-brand-item" src="@/assets/combine.png" alt="logo" />
                </router-link>
                <!-- Logo END -->

                <!-- Responsive navbar toggler -->
                <button class="navbar-toggler ms-auto me-3 p-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-animation">
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                </button>
                
                <div class="navbar-collapse collapse" id="navbarCollapse">
                    <ul class="navbar-nav navbar-nav-scroll mx-auto">
                        <li class="nav-item">
                            <router-link class="nav-link custom-menu" to="/">HOME</router-link>
                        </li>

                        <li class="nav-item">
                            <router-link class="nav-link custom-menu" to="/aboutpage">ABOUT US</router-link>
                        </li>

                        <li class="nav-item">
                            <router-link class="nav-link custom-menu" to="/bookings">BOOKINGS</router-link>
                        </li>

                        <li class="nav-item">
                            <router-link class="nav-link custom-menu" to="/suggest">SUGGEST TEMPLE</router-link>
                        </li>
<!-- 
                        <li class="nav-item">
                            <router-link class="nav-link custom-menu" to="/eventlist">EVENTS</router-link>
                        </li> -->

                        <li class="nav-item">
                            <a target="_blank" class="nav-link custom-menu" href="https://www.devalayas.in/index.html#reservation">CONTACT US</a>
                        </li>
                    </ul>
                </div>

                <!-- Main navbar START -->
                <ul class="nav flex-row align-items-center list-unstyled ms-xl-auto">
                    <li class="nav-item ms-3 dropdown">
                        <!-- Avatar -->
                        <a class="avatar avatar-lg p-0" href="#" id="profileDropdown" role="button" data-bs-auto-close="outside" data-bs-display="static" data-bs-toggle="dropdown" aria-expanded="false">
                            <!-- <img class="avatar-img rounded-2" src="@/assets/avatar.png" alt="avatar" /> -->
                            <img src="../assets/avatar.png">

                        </a>

                        <ul class="dropdown-menu dropdown-animation dropdown-menu-end shadow pt-3" aria-labelledby="profileDropdown">
                            <!-- Profile info -->
                            <li class="px-3 mb-3">
                                <div class="d-flex align-items-center">
                                    <!-- Avatar -->
                                    <div class="avatar me-3">
                                        <img class="avatar-img rounded-circle shadow" src="@/assets/avatar.png" alt="avatar" />
                                    </div>
                                    <div>
                                        <router-link to="/profile" v-if="$store.getters.user.name==''" class="h6 mt-2 mt-sm-0">VLE-CSC</router-link>
                                        <router-link to="/profile" v-else class="h6 mt-2 mt-sm-0" href="#">{{ $store.getters.user.name }}</router-link>
                                        <p v-if="$store.getters.user.mobile_number" class="small mt-2">{{ $store.getters.user.mobile_number }}</p>
                                        <p v-else class="small mt-2">{{ $store.getters.user.csc_id }}</p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <hr class="dropdown-divider" />
                            </li>
                            <li>
                                <router-link class="dropdown-item bg-danger-soft-hover" to="/profile">
                                    <i class="bi bi-info-circle fa-fw me-2"></i>
                                    My Profile
                                </router-link>
                            </li>
                            <li>
                                <a class="dropdown-item bg-danger-soft-hover" href="#" @click.prevent="logout">
                                    <i class="bi bi-power fa-fw me-2"></i>
                                    Sign Out
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </nav>
        <!-- Logo Nav END -->
    </header>
</template>

<script>
    export default {
        name: "Header",
		methods:{
            logout(){
                this.$store.dispatch('logout');
                window.open("https://stagingvle.cscestore.in/auth/logout");
                this.$router.push('/login');
            },
        },
    };
</script>

<style>
	.custom-menu{
		font-size: auto;
        color: rgb(5, 5, 5);
        font-family:commanders;
	}
</style>
