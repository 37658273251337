<template>
    <main>
        <section>
            <div class="container d-flex px-0 px-sm-4">
                <div class="row justify-content-center align-items-center m-auto">
                    <div class="col-12">
                        <div class="bg-mode shadow rounded-3 overflow-hidden">
                            <div class="row g-0">
                                <div class="col-lg-6 d-flex align-items-center order-2 order-lg-1">
                                    <div class="p-3 p-lg-5">
                                        <img style="width: 80%;" class="mb-4" src="@/assets/banner.svg" alt="" />
                                    </div>
                                    <div class="vr opacity-2 d-none d-lg-block"></div>
                                </div>
                                <div class="col-lg-6 order-1">
                                    <div class="p-1 p-sm-6 text-center">
                                        <a href="#">
                                            <img class="h-100px mb-4" src="@/assets/logo.png" alt="logo" />
                                        </a>
                                        <h1 class="mb-2 h4">Login on CSC Grameen eStore Portal</h1>  
                                        <a href="https://vle.cscestore.in/">
                                                <img style="margin-top: 20px;" src="https://cscestore.in/dist/backend/images/logos/Grameen-Main-logo.svg" alt="Grameen E-Commerce" class="img-fluid" href="https://vle.cscestore.in/">
                                        </a>                                      
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
import axios from 'axios';
export default {
    name: "Login",
    data() {
        return {
            user: {
                mobile_number: "",
                login_token: "",
                app_version: "",
                device_model: "",
                user_type: "",
            },
            csc : {
                csc_id:"",
                key:"",
            },
            error: {
                mobile_no: "",
            },
            errors: []
        };
    },

    mounted(){
        let vm = this;
        vm.csc.csc_id = vm.$route.query.csc_code
        vm.csc.key = vm.$route.query.vle_authtoken
        console.log(vm.csc)
        if(vm.csc.csc_id && vm.csc.key) {
            vm.autoLogin();
        }
    },

    methods: {
        // ####Login#
        autoLogin() {
            let vm = this;
            let loader = vm.$loading.show();
            vm.$store.dispatch("auth", { uri: "csc/auth/token/", data: vm.csc })
            .then((response) => {
                loader.hide();
                vm.$store.dispatch("setUser", response.data.data);
                vm.$store.dispatch("setvleid", response.data.id);   
                vm.$store.dispatch("setToken", response.data.token);
                vm.$store.dispatch("setTokenType", "Bearer");
                vm.$router.push("/");
            })
            .catch((error) => {
                loader.hide();
                vm.$store.dispatch("error", error.response.data.csc_code);
            });
        },
    }
};
</script>
